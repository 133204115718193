import { getCookie, setCookie } from 'react-use-cookie';


// GLOBAL CONST
export const pageLimitPhotos = 8; //pagination
export const pageLimitVideos = 6; //pagination
export const systemCookies = {
    concent: 'ndpwebsitecookies',
    language: 'ndplanguage',
}

const detectLanguage = () => { // language logic
    let languages, lg = '';
    const saveCookie = (cookieName: string, value: string) => {
        setCookie(cookieName, value, {
            days: 99,
            SameSite: 'Strict',
            Secure: true,
        });
    };

    const languageCookie = getCookie(systemCookies.language);
    if (languageCookie) {
        lg = languageCookie;
    } else {
        languages = navigator.language || null;
        if (!languages) return 'ENG';
        lg = languages.slice(0, 2).toLowerCase();
    }
    switch (lg) {
        case 'es':
            if (!languageCookie) saveCookie(systemCookies.language, 'es');
            return 'ESP';
        case 'en':
            if (!languageCookie) saveCookie(systemCookies.language, 'en');
            return 'ENG';
        default: return 'ENG';
    }
}
export const currentLang = detectLanguage();
export const ytChannel = {
    name: '@nicodottaphoto',
    url: 'https://www.youtube.com/@nicodottaphoto',
}
export const page500PX = {
    name: '@nicodottaphoto',
    url: 'https://500px.com/p/nicodottaphoto',
}
export const email = "nicodottaphoto@icloud.com";
export const blockMouse = true;
export const trimTextlength = 200;
export const nftUrl = 'https://opensea.io/ndphoto';

// pages
export const pages = {
    blog: "/blog/*",
    brands: "/brands/*",
    collection: "/collection/*",
    contact: '/contact/*',
    copyright: '/copyright/*',
    home: "",
    info: "/info/*",
    newsletter: "/newsletter/*",
    photos: "/photos/*",
    videos: "/videos/*",
    nft: "/nft/*",
}


export interface linksValueType {
    title: string;
    url: string;
}
export interface linksType {
    [key: string]: linksValueType;
}
export const links: linksType =
{
    '500px': {
        title: '500px',
        url: 'https://500px.com/p/nicodottaphoto?view=photos',
    },
    'instagram': {
        title: 'Instagram',
        url: 'https://www.instagram.com/nicodottaphoto/',
    },
    'x': {
        title: 'X (Twitter)',
        url: 'https://twitter.com/home',
    },
    'facebook': {
        title: 'Facebook',
        url: 'https://www.facebook.com/nicodottaphoto',
    },
};

export interface languageType {
    'ESP': string;
    'ENG': string;
}

export interface textContentType {
    [key: string]: languageType;
}

export const textContent: textContentType = {
    'menu-inicio': {
        'ESP': 'Inicio',
        'ENG': 'Home',
    },
    'menu-fotos': {
        'ESP': 'Fotos',
        'ENG': 'Photos',
    },
    'menu-video': {
        'ESP': 'Videos',
        'ENG': 'Videos',
    },
    'menu-information': {
        'ESP': 'Información',
        'ENG': 'Information',
    },
    'menu-contacto': {
        'ESP': 'Contacto',
        'ENG': 'Contact',
    },
    'menu-copyright': {
        'ESP': 'Derechos de autor',
        'ENG': 'Copyright',
    },
    'menu-blog': {
        'ESP': 'Blog',
        'ENG': 'Blog',
    },
    'menu-opciones': {
        'ESP': 'Creador de Contenidos',
        'ENG': 'Content Creator',
    },
    'open-new-tab': {
        'ESP': 'Abrir en nueva pestaña',
        'ENG': 'Open in new tab',
    },
    'home-transform-title': {
        'ESP': 'Transformación a través de la Fotografía',
        'ENG': 'Transformation through Photography',
    },
    'home-transform-p1': {
        'ESP': 'La fotografía de paisajes no solo captura la majestuosidad de la naturaleza, sino que también despierta en nosotros una transformación interna.',
        'ENG': 'Landscape photography not only captures the majesty of nature, but also awakens an inner transformation within us.',
    },
    'home-transform-p2': {
        'ESP': 'Cada imagen nos invita a perdernos en la inmensidad del mundo, explorando no solo geografía, sino también nuestra conexión emocional con el entorno.',
        'ENG': 'Each image invites us to lose ourselves in the immensity of the world, exploring not only geography, but also our emotional connection with the environment.',
    },
    'home-transform-p3': {
        'ESP': 'Estas fotos se convierten en espejos que reflejan nuestra existencia, inspirándonos a descubrir tanto el mundo que nos rodea como la amplitud de nuestro propio ser.',
        'ENG': 'These photos become mirrors that reflect our existence, inspiring us to discover both the world around us and the breadth of our own being.',
    },
    'autor': {
        'ESP': '© Nicolas Dotta (@nicodottaphoto) ',
        'ENG': '© Nicolas Dotta (@nicodottaphoto) ',
    },
    'derechos-autor': {
        'ESP': 'Derechos de autor',
        'ENG': 'Copyright',
    },
    'newsletter': {
        'ESP': 'Newsletter',
        'ENG': 'Newsletter',
    },
    'newsletter-message': {
        'ESP': 'Suscríbete al Newsletter y recibe las últimas novedades.',
        'ENG': 'Subscribe to the Newsletter and receive the latest news.',
    },
    'marcas-message': {
        'ESP': 'Marcas, patrocinios y términos comerciales.',
        'ENG': 'Trademarks, sponsorships and commercial terms.',
    },
    'copiright-atention': {
        'ESP': 'Gracias por su interés. \
                Le recordamos que esta pagina y su contenido se encuentra protegida por derechos de autor. \
                Puede contactarse con el fotógrafo siguiendo el link a “Contacto” para \
                solicitar su uso. Muchas Gracias.',
        'ENG': 'Thank you for your interest in this photograph. \
                We remind you that this page and its content are protected by copyright. \
                You can contact the photographer by following the “Contact” \
                to request its use. Thank you so much.',
    },
    'cerrar-ventana': {
        'ESP': 'Cerrar',
        'ENG': 'Close',
    },
    'ampliar-foto': {
        'ESP': 'Ampliar',
        'ENG': 'Enlarge'
    },
    'titulo-videos': {
        'ESP': 'Videos Publicados',
        'ENG': 'Published Videos'
    },
    'titulo-fotos': {
        'ESP': 'Colecciones',
        'ENG': 'Collections',
    },
    'navigation-text': {
        'ESP': 'Ir a ',
        'ENG': 'Go to ',
    },
    'pagination-pagina': {
        'ESP': 'Pagina',
        'ENG': 'Page',
    },
    'pagination-pagina-de': {
        'ESP': 'de',
        'ENG': 'of',
    },
    'ver-mas-fotos-500px': {
        'ESP': 'Ver más fotos en el sitio web de 500px',
        'ENG': 'See more photos at 500px website',
    },

    // PAGES META STRINGS
    'meta-homepage-title': {
        'ESP': '@nicodottaphoto - Fotógrafo de Paisajes',
        'ENG': '@nicodottaphoto - Landscape Photographer',
    },
    'meta-homepage-description': {
        'ESP': 'Creador de contenidos, amante de la naturaleza, fotógrafo apasionado y  seguidor de las buenas costumbres',
        'ENG': 'Content creator, nature lover, passionate photographer, and follower of good habits.',
    },
    'meta-photos-title': {
        'ESP': 'Fotografías tomadas por @nicodottaphoto',
        'ENG': 'Photographs taken by @nicodottaphoto',
    },
    'meta-photos-description': {
        'ESP': 'Fotografía de paisajes.',
        'ENG': 'Landscape photographer.',
    },
    'meta-photos-collection-title': {
        'ESP': 'Fotografías por @nicodottaphoto',
        'ENG': 'Photographs by @nicodottaphoto',
    },
    'meta-photos-collection-description': {
        'ESP': 'Una colección de imágenes bajo la categoría: ',
        'ENG': 'A collection of images under the category:',
    },
    'meta-bigphoto-title': {
        'ESP': ' (por @nicodottaphoto)',
        'ENG': ' (by @nicodottaphoto)',
    },
    'meta-bigphoto-description': {
        'ESP': '',
        'ENG': '',
    },
    'meta-video-title': {
        'ESP': 'Youtube videos por @nicodottaphoto',
        'ENG': 'Youtube Videos by @nicodottaphoto',
    },
    'meta-video-description': {
        'ESP': 'Videos centrados en la fotografía y desarrollo personal',
        'ENG': 'Videos focused on photography and personal development',
    },

    'meta-video-yt-title': {
        'ESP': ' (por @nicodottaphoto)',
        'ENG': ' (by @nicodottaphoto)',
    },
    'meta-video-yt-description': {
        'ESP': 'Video publicado en Youtube por @nicodottaphoto',
        'ENG': 'Video posted on YouTube by @nicodottaphoto',
    },


    // COOKIES MODAL
    'cookie-boton-acept': {
        'ESP': 'Aceptar / Cerrar',
        'ENG': 'Acept / Close',
    },
    'cookie-description-title': {
        'ESP': 'Notificación de Uso de Cookies',
        'ENG': 'Cookie Usage Notification',
    },
    'cookie-description-text': {
        'ESP': 'Este sitio web utiliza cookies para mejorar tu experiencia de navegación y para fines de análisis mediante Google Analytics. Al continuar utilizando este sitio, aceptas nuestro uso de cookies. Las cookies son pequeños archivos de texto almacenados en tu dispositivo para ayudarnos a comprender cómo interactúas con nuestro sitio web y cómo podemos mejorarlo. La información recopilada incluye datos como la cantidad de visitantes, las páginas más populares y la duración de la sesión. Si prefieres no aceptar cookies de Google Analytics, puedes cambiar la configuración de tu navegador para bloquearlas. Sin embargo, ten en cuenta que esto puede afectar la funcionalidad del sitio y limitar tu experiencia de usuario. Al hacer clic en "Aceptar" o al continuar navegando por este sitio, estás aceptando nuestro uso de cookies. Para obtener más información sobre cómo utilizamos las cookies y cómo puedes administrarlas, consulta nuestra Política de Privacidad y Cookies.',
        'ENG': 'This website uses cookies to enhance your browsing experience and for analytics purposes through Google Analytics. By continuing to use this site, you agree to our use of cookies. Cookies are small text files stored on your device to help us understand how you interact with our website and how we can improve it. The information collected includes data such as the number of visitors, most popular pages, and session duration. If you prefer not to accept Google Analytics cookies, you can change your browser settings to block them. However, please note that this may affect site functionality and limit your user experience. By clicking "Accept" or by continuing to browse this site, you are consenting to our use of cookies. For more information on how we use cookies and how you can manage them, please refer to our Privacy and Cookie Policy.',
    },
    // Copiright PAGE
    'page-copy-text': {
        'ESP': 'Todas las imágenes y videos presentados en este sitio web son propiedad del fotógrafo y están protegidos por la ley de derechos de autor. Queda estrictamente prohibido copiar, reproducir o distribuir el material sin autorización expresa. Si deseas utilizar alguna de las obras mostradas, por favor contáctanos para solicitar el permiso correspondiente. Agradecemos su respeto hacia el trabajo del autor.',
        'ENG': 'All images and videos featured on this website are the property of the photographer and are protected by copyright law. It is strictly prohibited to copy, reproduce, or distribute the material without express permission. If you wish to use any of the showcased works, please contact us to request the appropriate permission. We appreciate your respect for the photographer\'s work.',
    },

    // CONTACT PAGE
    'page-contact-title': {
        'ESP': 'Formas de Contacto',
        'ENG': 'Contact Methods',
    },
    'page-contact-text': {
        'ESP': 'Puedes contactarte a través del siguiente e-mail: ',
        'ENG': 'You can contact us through the following email:',
    },
    'page-contact-link': {
        'ESP': 'Click aqui',
        'ENG': 'CLi',
    },
    'page-contact-socials': {
        'ESP': 'O a travez de las siguientes plataformas:',
        'ENG': 'Or through the following platforms:',
    },
    'page-contact-links': {
        'ESP': 'Otras paginas:',
        'ENG': 'Other pages:',
    },

    // INFORMATION PAGE
    'page-information-title': {
        'ESP': 'Información',
        'ENG': 'Information',
    },
    'page-information-line-1': {
        'ESP': 'Nicolas Dotta, nacido en la ciudad de Montevideo, \
        Uruguay, demostró su interés por la fotografía y la producción \
        de videos desde una edad temprana. Aunque aún no había descubierto \
        cuál sería su pasión futura, se sumergió en un proceso de \
        aprendizaje desinteresado que gradualmente adquirió importancia en su vida. \
        Mientras muchos se enfocaban en ciertos puntos de vista sobre \
        temas específicos, él encontraba otros aspectos fascinantes. \
        Lo que al principio parecía curioso y anecdótico, con el tiempo se \
        convirtió en uno de los pilares fundamentales de su obra.',
        'ENG': 'Nicolas Dotta, born in the city of Montevideo, Uruguay, showed \
        his interest in photography and video production from an early age. Even \
        without discovering what his future passion would be, he embarked on a \
        selfless learning process that gradually gained importance in his life. \
        While many showed interest in certain viewpoints on specific things, \
        he found other aspects intriguing. What initially seemed curious and \
        anecdotal eventually became one of the fundamental pillars of his work.',
    },
    'page-information-line-2': {
        'ESP': 'Con el tiempo, descubrió una herramienta que lo ayudaría a \
        destacarse: un dispositivo tecnológico que facilitaba su expresión \
        en múltiples aspectos. Este dispositivo fue la puerta hacia grandes \
        cambios en su vida. La cámara fotográfica se convirtió en su \
        medio de expresión, permitiéndole comprender el mundo que lo rodeaba \
        y transmitir sus emociones.',
        'ENG': 'Over time, he discovered a tool that would help him stand \
        out: a technological device that facilitated expression in many aspects. \
        This became the gateway to significant changes in his life. \
        The camera became a means of expression, of understanding the world around him.',
    },
    'page-information-line-3': {
        'ESP': 'La fotografía de paisajes se convirtió en un foco de interés \
        crucial para él, y dedicó años a aprender sobre los mejores aspectos \
        dentro de este ámbito. Con el tiempo, comenzó a experimentar con nuevas \
        formas de conectar con la gente, descubriendo el impacto \
        significativo de las plataformas sociales y digitales en la vida de \
        las personas. En particular, YouTube e Instagram se convirtieron \
        en sus principales herramientas de difusión.',
        'ENG': 'Landscape photography became a very important focus of \
        interest for him, and he spent years learning about the best \
        aspects within this field. Over time, he began to experiment \
        with new ways to reach people, finding that social and digital \
        platforms had a strong impact on people\'s lives. Particularly \
        within this activity, YouTube and Instagram became \
        his primary tools for dissemination.',
    },
    'page-information-line-4': {
        'ESP': 'La creación de videos se convirtió en una necesidad para \
        contextualizar su fotografía. Como él mismo define esta actividad: \
        "Creando Memorias con la Cámara", forma parte de un todo que se complementa.',
        'ENG': 'The creation of videos became a necessity to give context \
        to his photography. As he defines this activity: "Creating Memories with \
        the Camera," it is part of a whole that complements each other.',
    },

    // 404 Not found
    'page-404-0-title': {
        'ESP': '404: Página no encontrada',
        'ENG': '404: Page Not Found',
    },
    'page-404-0-description': {
        'ESP': 'No podemos encontrar la página que estás buscando. Puede que haya sido eliminada, movida o que nunca haya existido.',
        'ENG': 'We can\'t find the page you\'re looking for. It might have been removed, moved, or never existed.',
    },

    //BRAND PAGE
    'brand-page-title': {
        'ESP': 'Marcas y Patrocinios',
        'ENG': 'Brands and Sponsorships',
    },
    'brand-page-subtitle': {
        'ESP': 'Información importante para anunciantes, marcas u otros que tengan interés en proveer sus productos.',
        'ENG': 'Important information for advertisers, brands or others interested in providing their products.',
    },
    'brand-page-description': {
        'ESP': 'Estaremos encantados de colaborar en la promoción de sus productos a través de nuestro canal de YouTube.\
                Para proceder con la promoción, es importante tener en cuenta los siguientes puntos:</br>\
                <b>Envío de productos</b></br>\
                    <div class="margin">El producto debe ser enviado a Uruguay. </br>\
                    El costo del envío, junto con los impuestos de importación, será responsabilidad de su empresa. Los productos pueden ser enviados a través de servicios de correo privado como DHL o FedEx. En este caso, usted deberá cubrir tanto los costos de envío como los impuestos de importación. Esta opción puede acelerar los tiempos de entrega.</div>\
                <b>Envío por correo postal convencional</b></br>\
                    <div class="margin">Si elige enviar el producto mediante correo postal convencional, una vez que el producto llegue a Uruguay y se nos notifique de su retención en aduanas, le solicitaremos que nos envíe la factura del producto. Con esta factura, podremos estimar los costos de importación que deben ser abonados. Estos costos deberán ser pagados por su empresa o por usted mediante transferencia a través de PayPal.</br>\
                    Importante: Si los costos de liberación no son abonados, lamentablemente el producto quedará retenido o perdido en aduanas.</div>\
                <b>Responsabilidad de envío y aduanas</b></br>\
                    <div class="margin">No nos hacemos responsables por la pérdida, extravío o retención del producto en aduanas. Recomendamos encarecidamente utilizar servicios de correo privado para agilizar los trámites y asegurar la entrega.</br>\
                    No realizaremos ningún tipo de pago anticipado para la liberación del producto en aduanas. Solo después de recibir la transferencia correspondiente por su parte, procederemos con los trámites necesarios.</div>\
                <b>Enlaces de afiliados</b></br>\
                    <div class="margin">Nos reservamos el derecho de incluir en el video final sobre su producto o insumo enlaces de afiliados de Amazon, donde el público podrá adquirirlo.</div>\
                ',
        'ENG': 'We would be happy to collaborate in promoting your products through our YouTube channel.\
                To proceed with the promotion, please consider the following points:</br>\
                <b>Product Shipping</b></br>\
                    <div class="margin">The product must be shipped to Uruguay.</br>\
                    The shipping cost, along with any import taxes, will be the responsibility of your company. Products can be shipped via private courier services such as DHL or FedEx. In this case, you will need to cover both the shipping and import tax costs. This option may expedite the delivery process.</div>\
                <b>Shipping via Standard Postal Service:</b></br><div class="margin">If you choose to send the product via standard postal service, once it arrives in Uruguay and we are notified of its hold in customs, we will ask you to provide the product\'s invoice. With this, we will estimate the import costs that need to be paid.\
                    These costs must be covered by your company or yourself via PayPal transfer.\
                    Important: If the import fees are not paid, the product will unfortunately be held or lost in customs.</div>\
                <b>Shipping and Customs Liability</b></br><div class="margin">We are not responsible for any loss, misplacement, or customs retention of the product. We highly recommend using private courier services to expedite the process and ensure safe delivery.\
                    We will not make any upfront payments for the product’s customs release. Once we receive the corresponding transfer from your side, we will proceed with the necessary customs processes.</div>\
                <b>Affiliate Links</b></br><div class="margin">We reserve the right to include affiliate links to Amazon in the final video discussing your product or item, allowing the audience to purchase it directly.</div>\
                ',
    },

    // BLOG PAGE
    'page-blog-title': {
        'ESP': 'Pensamientos de un Fotógrafo: Entre Imágenes y Reflexiones',
        'ENG': 'Thoughts of a Photographer: Between Shots and Vibes',
    },
    'page-blog-sub-title': {
        'ESP': 'Notas sobre fotografía, video y tendencias',
        'ENG': 'Notes on Photography, Video, and Trends',
    },
    'page-blog-sidebar-title': {
        'ESP': 'Temas',
        'ENG': 'Topics',
    },
    'page-blog-sidebar-latest': {
        'ESP': 'Ultimas publicaciones',
        'ENG': 'Entradas recientes',
    },    
    'page-blog-tags-description': {
        'ESP': 'Entrada publicada bajo los siguientes temas',
        'ENG': 'Entry published under the following topics',
    },
    'page-blog-read-more': {
        'ESP': 'Leer Mas',
        'ENG': 'Read More',
    },
    'page-blog-back-start': {
        'ESP': 'Volver al Inicio',
        'ENG': 'Back to Home',
    },
    'page-blog-entries': {
        'ESP': 'entradas',
        'ENG': 'entries',
    },
    'page-blog-entries-about': {
        'ESP': 'Entradas acerca de',
        'ENG': 'Entries about',
    },
    'page-blog-entries-description': {
        'ESP': 'Entrada acerca de ',
        'ENG': 'Entrie about ',
    },
    // NFTS PAGES
    'nfts-menu': {
        'ESP': 'NTFs (arte digital)',
        'ENG': 'NFTs (digital art)',
    },
    'nfts-link-generic': {
        'ESP': 'NTFs: Venta de Fotografias Digitales',
        'ENG': 'NFTs: Digital Photographs for Sale',
    },
    'nfts-page-tile': {
        'ESP': 'Venta de Fotografias Digitales (NTFs)',
        'ENG': 'Digital Photographs for Sale (NTFs)',
    },
    'nfts-page-subtile': {
        'ESP': 'Consigue una foto original como NFT, un archivo digital único que demuestra que eres el dueño y garantiza su autenticidad.',
        'ENG': 'Grab an original photo as an NFT, a one-of-a-kind digital file that proves you own and legit that image.',
    },
    'nfts-page-description-1': {
        'ESP': 'Puede ver la exclusiva galería de fotografías en venta en la plataforma de OpenSea. Allí encontrarás las fotos que actualmente están disponibles para su venta. Sigue el enlace a continuación:',
        'ENG': 'You can check out the exclusive gallery of photographs for sale on the OpenSea platform. There, you\'ll find the photos currently available for purchase. Follow the link below:',
    },
    'nfts-page-description-2': {
        'ESP': 'Nota: Por motivos de seguridad y transparencia, solo realizamos transacciones a través de la plataforma OpenSea. No dudes en hacer ofertas o compras directamente en el perfil de OpenSea. No manejamos ventas ni pagos fuera de la plataforma para asegurar la protección de ambas partes. Gracias.',
        'ENG': 'Note: For security and transparency reasons, I only conduct transactions through OpenSea. Please feel free to place any offers or make purchases directly on my OpenSea profile. I do not handle sales or payments outside of the platform to ensure both parties are protected. Thanks.',
    },

    // 404 not found
    'page-404-title': {
        'ESP': 'Error 404, el conteido no se encontró!',
        'ENG': '404 Not found!',
    },
    'page-404-sub-title': {
        'ESP': 'Oops! La página que buscas no está disponible. Puede que haya sido movida o ya no exista.',
        'ENG': 'Ooops! The page you\'re looking for is unavailable. It may have been moved or no longer exists.',
    },
    'page-404-go-back': {
        'ESP': '<-- Volver',
        'ENG': '<-- Go Back',
    },
};