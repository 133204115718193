import React, {useEffect, useState} from "react";
import { headersDataType } from "../../types";
import {
    CollectionTitle, translate,
    canonical
} from "../../../components/common/utils";
import SetHeaders from "../../aux/SetHeaders";
import { blogPosts, blogPostsType } from "../../../components/common/sources";
import BlogSideBar from "./BlogSideBar";
import InteralPageLinks from "../InteralPageLinks";
import BlogEntryPreview from "./BlogEntryPreview";

const BlogHome: React.FC = () => {
    const [metaTags, setMetaTags] = useState<headersDataType>();
    
    useEffect(() => {
        setMetaTags({
            title: translate('page-blog-title'),
            description: translate('page-blog-sub-title'),
            canonical: canonical('blog'),
        });
    }, []);

    return (
        <>
            {metaTags && <SetHeaders headerInfo={metaTags} />}
            <div className="Blog" >
                <CollectionTitle title={translate("page-blog-title")} />
                <div className="MainListing">
                    <div className="BlogContent">
                        {blogPosts
                            .sort((a, b) => b.date.valueOf() - a.date.valueOf())
                            .slice(0, 5)
                            .map((e: blogPostsType, key: number) =>
                                <BlogEntryPreview entry={e} key={key} />
                            )}
                    </div>
                    <div className="BlogSidebar">
                        <BlogSideBar />
                    </div>
                </div>
                <InteralPageLinks />
            </div >
        </>
    );
}

export default BlogHome;
